import { menuFromDOM } from './menuDefinition'
import React from 'preact/compat'
import { render } from 'preact'
import MainMenu from './MainMenu'
import { Provider } from 'react-redux'
import store from './store'

const initMainmenu = (
  menu: HTMLElement | null,
  desktopMenu: HTMLElement | null
): void => {
  if (!menu || !desktopMenu) {
    return
  }

  for (const el of document.querySelectorAll<HTMLElement>(
    '#mainmenu-desktop .nav-item.active'
  )) {
    el.classList.add('current-page')
  }

  const menuDefinition = menuFromDOM(menu)
  menu.parentElement!.removeChild(menu)
  store.dispatch({ type: 'definition/set', definition: menuDefinition })

  const wrap = document.createElement('div')
  document.body.appendChild(wrap)

  render(
    <Provider store={store}>
      <MainMenu />
    </Provider>,
    wrap,
    wrap.lastChild as Element
  )
}

export default initMainmenu

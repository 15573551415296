// Filter
$(document).ready(function(){
  
  if ($('.filter-outer').length) {
    
    // Prüfen, ob Items ausgegeben werden
    $('.filter-outer').each(function () {
      if ($(this).find('.filter-item:visible').length) {
        $(this).find('.no-results').hide();
      } else {
        $(this).find('.no-results').show();
      }
      // Gleiche Höhe für sichtbare Header bei Zeilen 
      if ($(this).find('.filter-item:visible').length > 1) {
        if ($(this).find('.teaser-lined').length) {
          $(this).find('.teaser-lined-head:visible').matchHeight();
          $(this).find('.teaser-lined-sub:visible').matchHeight();
        } else if ($(this).find('.teaser-news').length) {
          $(this).find('.teaser-news-head:visible').matchHeight();
        }
      }
    });
            
    // Selects für Filter
    var $filterSelect = $('select.select-filter');
      
    // Filter Product
    $filterSelect.change( function() {
          
      // Filter-Section
      var $filter = $(this).closest('.filter-outer');
    
      // Filter-Items und Filter-Sections
      var $filterItems = $filter.find('.filter-item');    
                
      var $filterValue = this.value;
      
      // Items mit Filter prüfen    
      $filterItems.each(function () {
              
        if ($filterValue.length) {
          // Es ist ein Filter gesetzt
          if ($(this).hasClass('item-'+$filterValue)) {
            // Filter passt
            $(this).show();
          } else {
            // Filter passt nicht
            $(this).hide();
          }
        } else {
          // Es ist kein Filter gesetzt
          $(this).show(); 
        }
  
        // Prüfen, ob Items ausgegeben werden
        if ($filter.find('.filter-item:visible').length) {
          $filter.find('.no-results').hide();
        } else {
          $filter.find('.no-results').show();
        }
      
      });
      
      // Update MatchHeight
      if ($filter.find('.teaser-lined').length) {
        if ($filter.find('.filter-item:visible').length > 1) {
          $filter.find('.teaser-lined-head:visible').matchHeight();
          $filter.find('.teaser-lined-sub:visible').matchHeight();
        } else {
          $filter.find('.teaser-lined-head').matchHeight({ remove: true });
          $filter.find('.teaser-lined-sub').matchHeight({ remove: true });
        }
      } else if ($filter.find('.teaser-news').length) {
        if ($filter.find('.filter-item:visible').length > 1) {
          $filter.find('.teaser-news-head:visible').matchHeight();
        } else {
          $filter.find('.teaser-news-head').matchHeight({ remove: true });
        }
      }
      
    
    });

    // Filter reset
    $('.reset-filter').click(function(e) {
      e.preventDefault();
      
      // Filter-Section
      var $filter = $(this).closest('.filter-outer');
      
      // Selects für Filter
      var $filterSelect = $filter.find('select.select-filter');
      
      $filterSelect.val('');
      $filterSelect.change();
      $filterSelect.selectpicker('refresh');
    });	

  }
    
});



var $filterCheckboxes = $('input[type="checkbox"]');
var filterFunc = function() {
  
  var selectedFilters = {};

  $filterCheckboxes.filter(':checked').each(function() {

    if (!selectedFilters.hasOwnProperty(this.name)) {
      selectedFilters[this.name] = [];
    }

    selectedFilters[this.name].push(this.value);
  });

  // create a collection containing all of the filterable elements
  var $filteredResults = $('.flower');

  // loop over the selected filter name -> (array) values pairs
  $.each(selectedFilters, function(name, filterValues) {

    // filter each .flower element
    $filteredResults = $filteredResults.filter(function() {

      var matched = false,
        currentFilterValues = $(this).data('category').split(' ');

      // loop over each category value in the current .flower's data-category
      $.each(currentFilterValues, function(_, currentFilterValue) {

        // if the current category exists in the selected filters array
        // set matched to true, and stop looping. as we're ORing in each
        // set of filters, we only need to match once

        if ($.inArray(currentFilterValue, filterValues) != -1) {
          matched = true;
          return false;
        }
      });

      // if matched is true the current .flower element is returned
      return matched;

    });
  });

  $('.flower').hide().filter($filteredResults).show();
}

$filterCheckboxes.on('change', filterFunc);  

import 'core-js/es/weak-set'
import 'core-js/es/symbol'
import 'core-js/es/promise'
import 'core-js/features/array/from'
import 'core-js/features/array/includes'
import 'core-js/features/array/find'
import 'core-js/features/object/assign'
import 'element-closest-polyfill'

import './parcel_fixes'
import './modules/sideButtons'
import $ from './jquery-global'
import 'popper.js'
import 'bootstrap'
import 'bootstrap-select'
import './parsley'
import './navbar'
//import './header'
import './navi'
import './validate'
import './filter'
import './filter-neu'
import './ajaxfilter'
import 'cssHelpers/*.ts'

import 'mmenu-js/src/mmenu';
import 'mmenu-js/dist/mmenu.polyfills';

import '../../node_modules/jquery-match-height'
import '../../node_modules/aos/dist/aos.js'
import '../../node_modules/slick/index.js'
import '../../node_modules/slick-carousel/slick/slick.js'


import './modules/worldMap'
import scrollbarSlider from './modules/scrollbarSlider'
import accordionSlider from './modules/accordionSlider.ts'
import slider from './modules/slider'
import './modules/video'
import './modules/tabs'
import './modules/calculator.js'
import './vendor/jquery.fancybox.js'
import Swiper from 'swiper'
import AOS from 'aos';

		  
$('.collapse').on('shown.bs.collapse', function(e) {
  var $card = $(this).closest('.card');
  $('html,body').animate({
    scrollTop: $card.offset().top  - 150
  }, 500);
});



$(() => {
	let stickyTop = 0,
		scrollTarget = false;

	let timeline = $(".timeline__nav"),
		items = $("li", timeline),
		milestones = $(".timeline__section .milestone"),
		offsetTop = parseInt(timeline.css("top"));

	const TIMELINE_VALUES = {
		start: 420,
		step: 80
	};

	$(window)
		.resize(function () {
			timeline.removeClass("fixed");

			stickyTop = timeline.offset().top - offsetTop;

			$(window).trigger("scroll");
		})
		.trigger("resize");

	$(window)
		.scroll(function () {
			if ($(window).scrollTop() > stickyTop) {
				timeline.addClass("fixed");
			} else {
				timeline.removeClass("fixed");
			}
		})
		.trigger("scroll");

	items.find("span").click(function () {
		let li = $(this).parent(),
			index = li.index(),
			milestone = milestones.eq(index);

		if (!li.hasClass("active") && milestone.length) {
			scrollTarget = index;

			let scrollTargetTop = milestone.offset().top - 80;

			$("html, body").animate(
				{ scrollTop: scrollTargetTop },
				{
					duration: 400,
					complete: function complete() {
						scrollTarget = false;
					}
				}
			);
		}
	});

	$(window)
		.scroll(function () {
			let viewLine = $(window).scrollTop() + $(window).height() / 3,
				active = -1;

			if (scrollTarget === false) {
				milestones.each(function () {
					if ($(this).offset().top - viewLine > 0) {
						return false;
					}

					active++;
				});
			} else {
				active = scrollTarget;
			}

			timeline.css(
				"top",
				-1 * active * TIMELINE_VALUES.step + TIMELINE_VALUES.start + "px"
			);

			items.filter(".active").removeClass("active");

			items.eq(active != -1 ? active : 0).addClass("active");
		})
		.trigger("scroll");
});



$( document ).ready(function() {
  setTimeout(function(){
    $('body').addClass('pageloaded');
    
}, 2000);


  $(".no-results").hide();
  $(".reset-filters").hide();

  $("select.w-filter-by").change(function() {
    let filters = $.map($("select.w-filter-by").toArray(), function(e) {
      return $(e).val();
    }).join(".");
    $(".w-results").find("li").hide();
    let results = $(".w-results").find("li." + filters);
    console.log(filters);
    if (results.length){
      results.show();
      $(".no-results").hide();
      $(".reset-filters").show();
    } else {
      $(".no-results").show();
      $(".reset-filters").show();
    }
  });
  // Filter reset
  $('.reset-filters').click(function(e) {
    $(".w-results").find("li").show();
  });	
});




  //var $navOffset = $('body').data('offset');
  
	if ($(window).width() < 992) {
		var $navOffset = 100;
	} else {
    var $navOffset = 130;
	} 

 /* var content = document.getElementById("techdatas");
var button = document.getElementById("show-more");

button.onclick = function () {

	if(content.className == "open my-3 columns"){
    $('#show-more').addClass('active');
		//shrink the box
		content.className = "my-3 columns";
    $('#show-more').removeClass('active');
	} else {
		//expand the box
		content.className = "open my-3 columns";
    $('#show-more').addClass('active');
	}

};
*/

$('body').on('click', '*[data-href]', function (this: HTMLElement, e) {
  e.preventDefault()
  const a = document.createElement('a')
  a.href = $(this).data('href')
  if ($(this).data('target') === '_blank') {
    window.open(a.href, '_blank')
  } else {
    location.href = a.href
  }
})



$(window).on("load resize scroll", function() {
  $(".bg-relative").each(function() {
    var windowTop = $(window).scrollTop() + 400;
    var elementTop = $(this).offset().top;
    var leftPosition = windowTop - elementTop;
      $(this)
        .find(".bg-move")
        .css({ left: leftPosition });
  });
});

(function($) {

  $.fn.visible = function(partial, hidden) {

    var $t = $(this).eq(0),
      t = $t.get(0),
      $w = $(window),
      viewTop = $w.scrollTop(),
      viewBottom = viewTop + $w.height(),
      _top = $t.offset().top,
      _bottom = _top + $t.height(),
      compareTop = partial === true ? _bottom : _top,
      compareBottom = partial === true ? _top : _bottom,
      clientSize = hidden === true ? t.offsetWidth * t.offsetHeight : true;

    return !!clientSize && ((compareBottom <= viewBottom) && (compareTop >= viewTop));
  };

})(jQuery);


// Scrolling Functions
$(window).scroll(function(event) {
  function padNum(num) {
    if (num < 10) {
      return "" + num;
    }
    return num;
  }

  var first = parseInt($('.c1').text());
  var second = parseInt($('.c2').text());
  var third = parseInt($('.c3').text());

  function countStuffUp(points, selector, duration) { //Animate count
    $({
      countNumber: $(selector).text()
    }).animate({
      countNumber: points
    }, {
      duration: duration,
      easing: 'linear',
      step: function() {
        $(selector).text(padNum(parseInt(this.countNumber)));
      },
      complete: function() {
        $(selector).text(points);
      }
    });
  }

  // Output to first-count
  $(".first-count").each(function(i, el) {
    var el = $(el);
    if (el.visible(true)) {
      countStuffUp(first, '.first-count', 1600);
    }
  });

  // Output to second count
  $(".second-count").each(function(i, el) {
    var el = $(el);
    if (el.visible(true)) {
      countStuffUp(second, '.second-count', 2000);
    }
  });
  // Output to second count
  $(".third-count").each(function(i, el) {
    var el = $(el);
    if (el.visible(true)) {
      countStuffUp(third, '.third-count', 2500);
    }
  });

});
$(document).ready(function() {
 
  /*setTimeout(function(){
      $('body').addClass('loaded');
  }, 4000);*/

});



$(document).ready(function(){

$('.center').slick({
  vertical: true,
  verticalSwiping: true,
  centerMode: true,
  infinite: true,
  centerPadding: '0px',
  slidesToShow: 1,
  arrows: true,
  dots: false,
  prevArrow: '<span class="slick-prev" />',
  nextArrow: '<span class="slick-next" />',
});

$('.branchen-slider').slick({
  slidesToShow: 1,
  arrow: true,
  centerMode: false,
  prevArrow: '<span class="slick-prev"><i class="fa-light fa-angle-left"></i></span>',
  nextArrow: '<span class="slick-next"><i class="fa-light fa-angle-right"></i></span>',
  responsive: [{
      breakpoint: 992,
      settings: {
          slidesToShow: 1,
      }
  }]
});

    
});


$(document).ready(function(){
  $('input[type="checkbox"]').click(function(){
    var val = $(this).attr("value");
    $(".msg").removeClass("active");
    $("." + val).addClass("active");
  });
});


// Slider (Fullscreen)
$('.slider-fullscreen').each(function () {
  var slider = $(this);
  slider.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    infinite: true,
    autoplay: true, 
    autoplaySpeed: 5000,
    pauseOnHover: false,
    speed: 1200,
    fade: true,
    prevArrow: '<span class="slick-prev"><i class="fa-light fa-angle-left"></i></span>',
    nextArrow: '<span class="slick-next"><i class="fa-light fa-angle-right"></i></span>',
  });
});  

$(".slider-fullscreen-scroll a").click(function(event) {
  event.preventDefault();
  var parentSection = $(this).closest('section');
  var nextSection = parentSection.next('section');
  if ($(nextSection).length) {
    if ($(nextSection).offset()) {
      $('html,body').animate( { scrollTop:($(nextSection).offset().top - ($navOffset - 1))} , 800);
    }
  }
});	


// Slider (Karte)
$('.slider-map-nav').each(function () {
  var slider = $(this);
  slider.slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    speed:500,
    focusOnSelect: true,
    prevArrow: '<span class="slick-prev"><i class="fa-light fa-angle-left"></i></span>',
    nextArrow: '<span class="slick-next"><i class="fa-light fa-angle-right"></i></span>',
    asNavFor: '.slider-map',
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          speed:500,
          focusOnSelect: true,
          asNavFor: '.slider-map',
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          speed:500,
          focusOnSelect: true,
          asNavFor: '.slider-map',
        }
      }
    ]
  });
  slider.on('setPosition', function(e) {
    var slidesShown = $(slider).slick('slickGetOption', 'slidesToShow');
    var numberOfSlides = $(slider).find('.slick-slide').length;
    //console.log('slidesShown: '+slidesShown);
    //console.log('numberOfSlides: '+numberOfSlides);
    if (slidesShown === numberOfSlides) {
      $(slider).find('.slick-track').css('transform', 'translate3d(0px, 0px, 0px)');
    }
  });
}); 
$('.slider-map-image').each(function () {
  var slider = $(this);
  slider.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    infinite: false,
    fade: true,
    adaptiveHeight: false,
    speed: 500,
    //customPaging: function(slider, i) { 
    //	var title = $(slider.$slides[i]).find('.title').text();
    //	return '<div class="content">'+title+'</div>';
    //},
    //appendDots: slider.parent().find('.slider-map-dots'),
    asNavFor: '.slider-map',
  });
});  
$('.slider-map-text').each(function () {
  var slider = $(this);
  slider.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    infinite: false,
    fade: true,
    adaptiveHeight: true,
    speed: 500,
    asNavFor: '.slider-map',
  });
});  


AOS.init({
  // Global settings:
  disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
  startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
  initClassName: 'aos-init', // class applied after initialization
  animatedClassName: 'aos-animate', // class applied on animation
  useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
  disableMutationObserver: false, // disables automatic mutations' detections (advanced)
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
  throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
  

  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  offset: 120, // offset (in px) from the original trigger point
  delay: 200, // values from 0 to 3000, with step 50ms
  duration: 1000, // values from 0 to 3000, with step 50ms
  easing: 'ease', // default easing for AOS animations
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them
  anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

});

$(document).ready(function() {
  $("#example").DataTable({
    paging: false,
    searching: false,
    scrollX: true,
    info: false
  });
  
});



const hiddenItems = [...document.querySelectorAll('.news-item-row.hidden')];
const loadmore = document.getElementById('loadMore');

hiddenItems.splice(0, 4).forEach(
  elem => elem.classList.remove('hidden')
);

loadMore.addEventListener('click', function (e) {
  e.preventDefault();

  hiddenItems.splice(0, 4).forEach(
    elem => elem.classList.remove('hidden')
  )

  if (hiddenItems.length == 0) {
    loadMore.classList.add('hidden');
  }
});


